@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "ADLaM Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000e4b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Averia+Sans+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Signika:wght@300..700&display=swap");
.averia {
  font-family: "Averia Sans Libre", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.adlam {
  font-family: "ADLaM Display", serif;
  font-weight: 400;
  font-style: normal;
}

.text-stroke {
  text-shadow: -3px -3px 3px #d8d8ff40, 3px -3px 3px #d8d8ff40,
    -3px 3px 3px #d8d8ff40, 3px 3px 3px #d8d8ff40;
  filter: drop-shadow(0 0.75mm 0.5mm rgb(255, 255, 255, 0.5));
}

.blob {
  border-radius: 45% 55% 56% 44% / 42% 53% 47% 58%;
}

.blob2 {
  border-radius: 58% 42% 51% 49% / 66% 63% 37% 34%;
}

.blob3 {
  border-radius: 42% 58% 51% 49% / 52% 55% 45% 48%;
}
