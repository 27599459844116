.Calque_1_116_2 {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
}

.ObjetGénératif_116_3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ObjetGénératif_116_3 img {
    width: 100%;
    height: auto;
    max-width: 600px;
    margin: 0 auto;
}

.sapinContainer {
    position: relative;
    width: 100%;
    height: 85vh;
    max-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sapinWrapper {
    position: relative;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
}

.sapinWrapper img {
    width: 100%;
    height: auto;
    display: block;
}

.bouleContainer {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.imageContainer {
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    aspect-ratio: 1/1;
    transform: translate(-50%, -50%);
    pointer-events: auto;
}

.imageContainer:hover {
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}


@media (max-width: 1200px) {
    .imageContainer {
        width: 5vmin !important;
        height: 5vmin !important;
    }
}

/* Ajout des styles pour la taille des images en mobile */
@media (max-width: 768px) {
    .imageContainer {
        width: 7vmin !important;
        height: 7vmin !important;
    }
}

@media (max-width: 480px) {
    .imageContainer {
        width: 11vmin !important;
        height: 11vmin !important;
    }
}

/* Garder les media queries existantes pour le hover */
@media (max-width: 768px) {
    .imageContainer:hover {
        transform: translate(-50%, -50%) scale(1.5);
    }
}

@media (max-width: 480px) {
    .imageContainer:hover {
        transform: translate(-50%, -50%) scale(1.2);
    }
}

.dayImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* Ajuster la position du sapin sur mobile */
@media (max-width: 768px) {
    .sapinContainer {
        height: 90vh;
        margin-top: 3rem;
    }
}




@media (max-width: 768px) {
    .Calque_1_116_2 {
        width: 98%;
        height: 100%;
    }
}

@media (max-width: 480px) {
    .sapinContainer {
        height: 75vh;
        margin-top: 5rem;
    }
}

@media (max-width: 768px) {

    .sapinWrapper {
        width: 100%;
    }
}

@media (max-width: 768px) {

    .bouleContainer {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 90%;
        pointer-events: none;
        margin-left: 20px;
    }
}